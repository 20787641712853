<template>
  <div class="app_page_container" style="display: flex;flex-direction: column">
    <van-nav-bar
        title="赛事情况"
    />
    <h2 style="text-align: center;font-size: 1.2em;" v-if="matchConfig && matchConfig.title">{{ matchConfig.title }}</h2>
    <div class="list_div">
      1、以单位（机构）为报名对象，选择自己的比赛组别、项目填写报名表，并提交盖章纸质报名表（pdf）；（纸质报名表见钉钉群附件）
    </div>
    <div class="list_div">
      2、比赛分为个人赛及团体赛两类，每名参赛选手最多可报一项个人类比赛和一项团体类比赛；
    </div>
    <div class="list_div">
      3、参赛时需提供参赛选择健康证明表（见钉钉群附件）。
    </div>
    <div class="list_div">
      4、比赛时间为2023年7月1日-7月2日，地点在浙江宁波海曙区；
    </div>
    <div class="list_div">
      5、报名截止时间为：{{ endTimeStr }}
    </div>

    <div v-if="btnVisible" style="width: 80%;margin: 14px auto auto;">
      <!--      <van-button @click="toFormType" :disabled="buttonDisabled" :loading="buttonLoading" :loading-text="'阅读中【 '+time+' 】'" block type="info">已阅读</van-button>-->
      <van-button @click="toFormType" block type="info">{{ btnName }}</van-button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import moment from "moment"
import {getOpenId} from "@/utils/common";

export default {
  name: 'Home',
  data() {
    return {
      btnVisible: false,
      btnName: '已阅读',
      startTimeStr: '',
      endTimeStr: ''
    }
  },
  computed: {
    ...mapGetters('match', ['matchConfig'])
  },
  methods: {
    createTimer() {
      let that = this
      let timeInterval = setInterval(() => {
        if (that.time === 1) {
          this.buttonDisabled = false
          this.buttonLoading = false
          clearInterval(timeInterval)
          return;
        }
        this.time = this.time - 1
      }, 1000)
    },
    toFormType() {
      if (getOpenId()) {
        this.$router.push({
          path: '/wx/teamType'
        })
      }
    }
  },
  created() {
    if (Object.keys(this.matchConfig).length > 0) {
      this.startTimeStr = this.matchConfig.signUpStartTime === '' ? '2020年4月10日0:00' : moment(this.matchConfig.signUpStartTime).format('YYYY年MM月DD日HH:mm')
      this.endTimeStr = this.matchConfig.signUpEndTime === '' ? '2020年4月10日0:00' : moment(this.matchConfig.signUpEndTime).format('YYYY年MM月DD日HH:mm')
      let startTime = this.matchConfig.signUpStartTime
      let endTime = this.matchConfig.signUpEndTime
      let nowTime = new Date().getTime()
      if (nowTime < startTime || nowTime > endTime) {
        this.btnVisible = false
      } else {
        this.btnVisible = true
      }
    } else {
      this.btnVisible = false
    }
  },
}
</script>
<style scoped>
.app_page_container {
  background-color: #FFF;
}

.list_div {
  padding: 14px;
  line-height: 22px;
  text-align: left;
}
</style>
